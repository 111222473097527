.hello{
    margin: 0px;
    padding: 0px;
    color: white;
     text-align: center;
     margin-top: 50px;
     margin-left: 20px;
   
   
}
.background{
    width: 100%;
    height: auto;
    
    background-color: black;
   
}

.v1{
    object-fit: cover;
    border-radius: 150px;
    height: 150px;
    width: 150px;
}
.v2{
  position: relative;
  top: 50px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
}
.text-1{
    text-align: center;
    color: white;
    font-size: 50px;
    margin-top: 100px;
}
.text-2{ 
    text-align: center !important;
  font-size: 60px;
    color: white;
    margin-top: 50px;

}
.btn-1{
    width: 150px;
    height: 40px;
    background-color: white;
    font-size: 18px;
    letter-spacing: 2px;
    text-align: center;
    border-radius: 100px;
  margin-right: 10px;
    border: none;

}
.btn-2{
    width: 180px;
    height: 40px;
    border: none;
    background-color: white;
    font-size: 18px;
    letter-spacing: 2px;
    text-align: center;
    border-radius: 100px;
    margin-left: 10px;
}
.img-2{
    width: 390px;
    height: auto;
   
}

.dd{
   position: relative;
   top: 15px;
}
.text-3{
    font-size: 60px;
}
.back-3{
    background-color: black;
    width: 100%;
    height:auto;
}
.text-5{
    color: white;
    text-align: center;
    font-size: 60px;
}
.img3{
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin-right: 20px;
}
.img4{
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin-top: 0px;
  
}
.pj1{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.pj2{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    
}
.pj3{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.im1:hover{
    opacity: 0.5 !important;
}
.im2:hover{
    opacity: 0.5 !important;
}
.im3:hover{
    opacity: 0.5 !important;
}
.im4:hover{
    opacity: 0.5 !important;
}
.con1{
    text-align: center;
    font-size: 60px;
}
.cont{
    width: 320px;
}
.form1{
    width: 300px;
}
.btn3{
    width: 150px;
    height: 40px;
    color: white;
    background-color: black;
    font-size: 18px;
    letter-spacing: 2px;
    text-align: center;
    border-radius: 100px;
  
    border: none;

}


@media  screen and (max-width: 444px) {
    .img3{
        width: 200px;
        height: 200px;
        object-fit: cover;
        margin-right: 0px;
       
    }
    .img4{
        width: 200px;
        height: 200px;
        object-fit: cover;
        
    }
  }
